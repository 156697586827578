import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

const fridaAdminJSON = {
  apiKey: "AIzaSyCqaga6fYhgwa_KfasAMFhGSwQ9GDq6UE0",
  authDomain: "frida-admin.firebaseapp.com",
  databaseURL: "https://frida-admin.firebaseio.com",
  projectId: "frida-admin",
  storageBucket: "frida-admin.appspot.com",
  messagingSenderId: "236241103398",
  appId: "1:236241103398:web:d910884aa322baaa9994e7",
  measurementId: "G-3QM734VQY7"
};

export const fridaFirebase = firebase.initializeApp(fridaAdminJSON);
export const persistence = firebase.auth.Auth.Persistence.LOCAL;
export const fb = firebase;
export const auth = fb.auth();
auth.setPersistence(persistence);
