import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#E8E8E8',
  },
  containerCards: {
    padding: 10,
  },
  imageContainer: {
    width: 60,
    height: 60,
    objectFit: 'contain',
    overflow: 'hidden'
  }
}));

export { useStyles }