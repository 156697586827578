import { CircularProgress } from "@material-ui/core";
import React from "react";
import "./loader.css";

interface Props {}

export const Loader: React.FC<Props> = () => {
  return (
    <div className="spinner">
      <CircularProgress size="large" />
    </div>
  );
};
