import React, { useEffect, useState } from 'react';
import { Button, Grid, Paper, TextField, Typography } from '@material-ui/core';
import axios from 'axios';
import { fb } from '../../../../constants/firebase';

const PassResetRequest = () => {
    const [user, setUser] = useState<string>('');
    const [pass, setPass] = useState<string>('');
    const [status, setStatus] = useState<string>(' ');

    useEffect(() => {
        if (status !== '') {
            setTimeout(() => {
                setStatus('');
            }, 3000);
        }
    }, [status])

    const requestChange = async () => {
        if (user !== '') {
            // const url = 'http://192.168.0.16:5001/automation-1713b/us-central1/PassReset-RequestChange';
            const url = 'https://us-central1-automation-1713b.cloudfunctions.net/PassReset-RequestChange';
            axios.post(url, {
                user: user
            })
                .then(res => {
                    setStatus(res.data);
                })
        }
    }

    return <Paper style={{ width: 300, margin: 30, padding: 20 }}>
        <Grid container direction='column'>
            <TextField
                label='Username / Email'
                value={user}
                onChange={e => setUser(e.target.value.trim())}
            />
            <Typography>{status}</Typography>
            <Button
                onClick={requestChange}
            >
                Request change
            </Button>
        </Grid>
    </Paper>
}


export default PassResetRequest;