import MainLayout from "./pages/routing";
// import { AuthProvider } from "./firebase/auth";
import './App.css';

function App() {
  return (
    <>
      <MainLayout />
     </>
  );
}

export default App;
