import React, { useEffect, useState } from "react";
// import { useAuth } from "../firebase/auth";
import { BrowserRouter, Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import NavBarComponent from '../../components/NavBar'
import Home from "../Home";
import * as DEMOS from '../Demos';
import { AppCatalog, FridaApp, Post } from '../../interfaces';
import { getAppList, getNewsList } from './service'

const browserHistory = createBrowserHistory();

interface Props { }

interface CompProps {
  isDarkMode: boolean;
  toggleTheme: (isChecked: boolean) => void;
}

const MainLayout: React.FC<Props> = () => {

  const [appsList, setAppsList] = useState<FridaApp[]>([])
  const [newsList, setNewsList] = useState<Post[]>([])
  const [auth, setAuth] = useState<boolean>(false);

  useEffect(() => {
    loadAppsList();
    loadNewsList();
  }, [])

  const loadAppsList = async () => {
    let res: AppCatalog = await getAppList();
    setAppsList(Object.values(res))
  }

  const loadNewsList = async () => {
    let res: Post[] = await getNewsList();
    setNewsList(res)
  }

  const HomePage = () => (
    <Home appsList={appsList} auth={auth} newsList={newsList} />
  )
  //eslint-disable-next-line

  return (
    <>
      <NavBarComponent auth={auth} setAuth={setAuth} />
      <BrowserRouter>
        <Router history={browserHistory}>
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path='/demos/innovation/resetpass' component={DEMOS.INNO.PassResetRequest} />
            <Route exact path='/demos/innovation/updatepass/:uid' component={DEMOS.INNO.PassResetUpdate} />
          </Switch>
        </Router>
      </BrowserRouter>
    </>
  );
};

export default MainLayout;
