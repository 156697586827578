import React, { useState } from 'react'
import { NavBarView } from './view'


interface Props{
    auth:boolean;
    setAuth:(val:boolean)=>void;
}
const NavBarComponent:React.FC<Props> = ({
    auth,
    setAuth,
}) => {
    
    const [openLogin, setOpenLogin] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAuth(event.target.checked);
    };

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout=()=>{
        setAuth(false)
    }
    const handleLogin=()=>{
        setOpenLogin(!openLogin)
        setAuth(true)
    }

    return (
            <NavBarView 
            handleChange={handleChange}         
            handleMenu={handleMenu} 
            handleClose={handleClose}
            handleLogout={handleLogout}
            handleLogin={handleLogin}
            auth={auth}
            open={open}
            anchorEl={anchorEl}
            openLogin={openLogin}
            setOpenLogin={setOpenLogin}
        />
    )
}

export default NavBarComponent