/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { useStyles } from './style'
import { Avatar, Grid, List, ListItem, ListItemAvatar, ListItemText, Paper, Typography } from "@mui/material";
import { Loader } from "../../components/Loader";
import { FridaApp, Post } from '../../interfaces/index';
import { Link, useHistory } from 'react-router-dom';

interface Props {
  path: string;
  isDarkMode?: boolean;
  toggleTheme?: (isChecked: boolean) => void;
  appsList: FridaApp[];
  auth: boolean;
  newsList: Post[];
}
const HomeView: React.FC<Props> = ({
  path,
  isDarkMode,
  toggleTheme,
  appsList,
  auth,
  newsList
}) => {
  const history = useHistory();
  const classes = useStyles();

  const formatDate = (input: string) => {
    if (!input.includes('-')) {
      let year = input.substring(0, 4);
      let month = input.substring(4, 6);
      let day = input.substr(6, 7);

      let dt = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
      return dt.toISOString().split('T')[0];
    } else {
      let dt = new Date(input);
      return dt.toISOString().split('T')[0];
    }
  }

  return (
    <>
      {
        appsList.length > 0
          ?
          <Grid container className={classes.container}>
            <Grid item sm={12} md={6} lg={8} className={classes.containerCards}>
              <Paper style={{ padding: 10 }}>
                <Typography variant="h4">Apps</Typography>
                <List>
                  {appsList.map((item: FridaApp, index: number) => {
                    return (
                      <ListItem
                        alignItems="flex-start"
                        key={index}
                        // style={{ border: 'solid' }}
                        onClick={() => { history.push(item.link) }}>
                        <ListItemAvatar style={{ padding: 15 }}>
                          <img
                            className={classes.imageContainer}
                            src={item.img}
                            srcSet={item.img}
                            alt={item.title}
                            loading="lazy"
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={item.title}
                          secondary={
                            <React.Fragment>
                              <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                              // color="#ff0000"
                              >
                                {item.description}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItem>)
                  })}
                </List>
              </Paper>
            </Grid>
            <Grid item sm={12} md={6} lg={4} className={classes.containerCards}>
              <Paper style={{ padding: 10 }}>
                <Typography variant="h4">News</Typography>
                {newsList &&
                  newsList.map((item: Post) => {
                    return (
                      <ListItem>
                        <ListItemText
                          primary={item.Title}
                          secondary={
                            <React.Fragment>
                              <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2">
                                (<i>{formatDate(item.Date)}</i>)&nbsp;-&nbsp;
                              </Typography>
                              <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                              // color="#ff0000"
                              >
                                {item.Body}
                              </Typography>
                              {item.URL && <Link to={item.URL}>Click to learn more...</Link>}
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                    )
                  })}
              </Paper>
            </Grid>
          </Grid>
          :
          <Grid item container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Loader />
            </Grid>
          </Grid>
      }
      <Grid item
        // xs={4} 
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className={classes.container}
        style={{ textAlign: 'center' }}
      >
        <img src={'./InnovationLogo.png'} />
      </Grid>
    </>
  );
};

export default HomeView;
