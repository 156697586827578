import React from "react";
import HomeView from "./view";
import { useRouteMatch } from "react-router-dom";
import { FridaApp, Post } from "../../interfaces";

interface Props {
  isDarkMode?: boolean;
  toggleTheme?: (isChecked: boolean) => void;
  appsList: FridaApp[];
  auth: boolean;
  newsList: Post[]
}

const Home: React.FC<Props> = ({
  isDarkMode,
  toggleTheme,
  appsList,
  auth,
  newsList
}) => {
  const { path } = useRouteMatch();
  return (
    <div
      style={{ width: '100%', height: '100%' }}
    >
      <HomeView
        path={path}
        isDarkMode={isDarkMode}
        toggleTheme={toggleTheme}
        appsList={appsList}
        auth={auth}
        newsList={newsList}
      />
    </div>
  );
};

export default Home;
