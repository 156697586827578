import { Drawer } from '@material-ui/core'
import React, { useState } from 'react'
import { LoginView } from './view'

interface Props{
    openLogin:boolean
    setOpenLogin:(val:boolean)=>void
}
export const LoginComponent:React.FC<Props> = ({
    openLogin,
    setOpenLogin,

}) => {

    const   POSITION='right'
    const toggleDrawer = () =>{
        setOpenLogin(!openLogin)
      };
    return (
        <div>
            <Drawer anchor={POSITION} open={openLogin} onClose={toggleDrawer} >
                <LoginView />
            </Drawer>
           
        </div>
    )
}
