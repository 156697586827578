interface Color{
    pink:string;
    purple:string;
    yellow:string;
    blue :string;
    green:string;
    red:string;
    orange:string;
    navyBlue:string;
}
const colors:Color={
    pink:'#E8308A',
    purple:'#7777B0',
    yellow:'#FFDE00',
    blue :'#5DA6DB',
    green:'#3CAA38',
    red:'#CB3C30',
    orange:'#EF7B23',
    navyBlue:'#1871B8',

}
export {colors}