import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Grid, Paper, TextField, Typography } from '@material-ui/core';
import axios from 'axios';

const PassResetUpdate = () => {
    let { uid } = useParams<{ uid: string }>();

    const [user, setUser] = useState<string>('');
    const [pass, setPass] = useState<string>('');
    const [status, setStatus] = useState<string>('');


    const doChange = async () => {
        if (uid !== '') {
            // const url = 'http://192.168.0.16:5001/automation-1713b/us-central1/PassReset-ChangePass';
            const url = 'https://us-central1-automation-1713b.cloudfunctions.net/PassReset-ChangePass';
            axios.post(url, {
                uid: uid,
                pass: pass
            })
                .then(res => {
                    setStatus(res.data);
                    setTimeout(() => {
                        setStatus('');
                    }, 3000);
                })
        }
    }

    return <Paper style={{ width: 300, margin: 30, padding: 20 }}>
        <Grid container direction='column'>
            <Typography>Your phone number is: +52******4798</Typography>
            <TextField
                label='New password'
                value={pass}
                onChange={e => setPass(e.target.value.trim())}
            />
            <Typography>{status}</Typography>
            <Button
                onClick={doChange}
            >
                Set new password
            </Button>
        </Grid>
    </Paper>
}

export default PassResetUpdate;