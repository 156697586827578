import { fridaFirebase } from '../../constants/firebase';
import { AppCatalog, Post } from '../../interfaces';

const dbRef = fridaFirebase.database().ref();

export const getAppList = async () => {
    const ref = dbRef;
    const res: AppCatalog = (await ref.child("/MosaicWebsite").once("value")).val();
    return res;
}

export const getNewsList = async () => {
    const ref = dbRef;
    const query = ref.child("/FRIDANews").orderByChild('Date').limitToLast(5);
    const res: { [key: string]: Post } = (await query.once("value")).val();
    return Object.values(res).sort(DateInverse);
}

function DateInverse(a: Post, b: Post) {
    if (a.Date > b.Date) {
        return -1;
    }
    if (a.Date < b.Date) {
        return 1;
    }
    return 0;
}