import { makeStyles } from "@material-ui/core";
import {colors} from '../../constants/colors'

// const useStyles = (auth:boolean)=> makeStyles((theme) => ({
const useStyles = makeStyles((theme) => ({
    drawer: {
        width: '250px',
    },
    
  }));

  export {useStyles}