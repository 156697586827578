import React from 'react'
import { Route, Switch } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { AccountCircle } from "@material-ui/icons";
import {useStyles} from './style'
import {LoginComponent} from '../Login'

interface Props{
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleMenu:(event: React.MouseEvent<HTMLElement>) => void;
    handleClose: () => void;
    handleLogout:() => void;
    handleLogin:() => void;
    auth:boolean;
    open:boolean;
    anchorEl: null | HTMLElement;
    openLogin:boolean;
    setOpenLogin:(val:boolean)=> void
}
export const NavBarView:React.FC<Props> = ({
    handleChange,
    handleMenu,
    handleClose,
    handleLogout,
    handleLogin,
    auth,
    open,
    anchorEl,
    openLogin,
    setOpenLogin,
}) => {
    const classes = useStyles(auth)();
    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar className={classes.toolBar}>
                    <div style={{paddingTop:'0px'}}>
                        <img src={'/FRIDA_Blanco.png'} width={'180px'} />

                    </div>
                </Toolbar>
            </AppBar>
            <LoginComponent openLogin={openLogin} setOpenLogin={setOpenLogin} />
            </div>
    )
}
