import { makeStyles } from "@material-ui/core";
import {colors} from '../../constants/colors'

const useStyles = (auth:boolean)=> makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      // color:colors.yellow,
      color:'white',
      fontWeight:600,
      marginLeft:auth?'52px':'52px'
    },
    toolBar:{
        backgroundColor:colors.pink,
        
    },
  }));

  export {useStyles}