import React from 'react'
import {useStyles} from './style'
interface Props{

}
export const LoginView:React.FC<Props> = ({

}) => {
    const classes=useStyles()
    return (
        <div className={classes.drawer}>
            <h1>Login!!</h1> 
        </div>
    )
}
